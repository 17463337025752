const ElementaryGrammarList = [
  {
    topic: "Word Order",
    id: "E001",
    keywords: ["word", "order", "word order"],
    level: "Elementary",
    url: "/grammar/elementary/wordOrder",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Subject Pronouns",
    id: "E002",
    keywords: ["subject", "pronouns", "subject pronouns"],
    level: "Elementary",
    url: "/grammar/elementary/subjectPronouns",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Object Pronouns",
    id: "E003",
    keywords: ["object", "pronouns", "object pronouns"],
    level: "Elementary",
    url: "/grammar/elementary/objectPronouns",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Parts of Speech",
    id: "E004",
    keywords: ["parts", "speech", "parts of speech"],
    level: "Elementary",
    url: "/grammar/elementary/partsOfSpeech",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "To Be",
    id: "E005",
    keywords: [
      "present",
      "simple",
      "present simple",
      "simple Present",
      "be",
      "am",
      "is",
      "are",
      "tenses",
    ],
    level: "Elementary",
    url: "/grammar/elementary/toBePresent",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "To Have",
    id: "E006",
    keywords: [
      "present",
      "simple",
      "have",
      "has",
      "tense",
      "present simple",
      "simple Present",
    ],
    level: "Elementary",
    url: "/grammar/elementary/toHave",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Present Simple",
    id: "E007",
    keywords: [
      "present",
      "simple",
      "tense",
      "present simple",
      "simple present",
    ],
    level: "Elementary",
    url: "/grammar/elementary/presentSimple",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Possessive Adjectives",
    id: "E008",
    keywords: [
      "adjectives",

      "possessives",
      "possession",
      "Possessive adjectives",
    ],
    level: "Elementary",
    url: "/grammar/elementary/possessiveAdjectives",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "A / An, & Plurals",
    id: "E009",
    keywords: ["a", "an", "plurals", "singular"],
    level: "Elementary",
    url: "/grammar/elementary/aAnAndPlurals",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "This / That / These / Those",
    id: "E010",
    keywords: ["this", "that", "those", "these"],
    level: "Elementary",
    url: "/grammar/elementary/thisThesethatThose",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Adjectives",
    id: "E011",
    keywords: ["adjectives"],
    level: "Elementary",
    url: "/grammar/elementary/adjectives",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Imperatives",
    id: "E012",
    keywords: ["imperative", "tense"],
    level: "Elementary",
    url: "/grammar/elementary/imperatives",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Let's",
    id: "E013",
    keywords: ["let's"],
    level: "Elementary",
    url: "/grammar/elementary/lets",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Questions",
    id: "E014",
    keywords: [
      "questions",
      "order",
      "auxiliary",
      "modals",
      "where",
      "when",
      "what",
      "whose",
      "which",
      "why",
      "who",
      "whom",
    ],
    level: "Elementary",
    url: "/grammar/elementary/questions",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Possessive 's",
    id: "E015",
    keywords: ["possessives", "possession", "'s", "s", "whose"],
    level: "Elementary",
    url: "/grammar/elementary/possessiveS",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Prepositions",
    id: "E016",
    keywords: [
      "prepositions",
      "in",
      "at",
      "on",
      "of",
      "from",
      "after",
      "before",
      "with",
      "than",
    ],
    level: "Elementary",
    url: "/grammar/elementary/prepositions",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Adverbs of Frequency",
    id: "E017",
    keywords: [
      "adverbs",
      "frequency",
      "adverbs of frequency",
      "always",
      "usually",
      "often",
      "sometimes",
      "hardly",
      "never",
      "how often",
    ],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Expressions of Frequency",
    id: "E018",
    keywords: ["frequency", "how often", "adverbs", "every day"],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Can / Can't",
    id: "E019",
    keywords: ["can", "cannot", "can't", "modal", "auxiliary"],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Present Continuous",
    id: "E020",
    keywords: ["present", "continuous", "tense", "present continuous"],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Like + v-ing",
    id: "E021",
    keywords: ["like", "love", "hate", "mind", "ing", "gerund", "two verbs"],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Simple Past (to be)",
    id: "E022",
    keywords: [
      "past",
      "tense",
      "be",
      "was",
      "were",
      "simple past",
      "past simple",
    ],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Simple Past (regular verbs)",
    id: "E023",
    keywords: ["past", "tense", "regular", "simple past", "past simple"],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Simple Past (irregular verbs)",
    id: "E024",
    keywords: ["past", "simple past", "past simple", "tense", "irregular"],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Some / Any",
    id: "E025",
    keywords: ["some", "any"],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "There Was / Were",
    id: "E026",
    keywords: ["there", "was", "were", "there was", "there were"],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Uncountable Nouns",
    id: "E027",
    keywords: [
      "countable",
      "count",
      "uncountable",
      "mass",
      "non-count",
      "noncount",
      "noun",
    ],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Quantifiers",
    id: "E028",
    keywords: [
      "many",
      "much",
      "lot",
      "lots",
      "little",
      "few",
      "a lot",
      "a lot of",
      "lots of",
      "a few",
      "a little",
    ],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Comparatives",
    id: "E029",
    keywords: ["comparatives", "than", "better", "comparison"],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Superlatives",
    id: "E030",
    keywords: ["superlatives", "best", "comparison"],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Be Going to",
    id: "E031",
    keywords: ["going", "be going to", "gonna", "plan", "intention", "future"],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Adverbs",
    id: "E032",
    keywords: ["adverbs"],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "V.1 + to + V.2",
    id: "E033",
    keywords: ["two verbs", "to", "infinitive", "base form"],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Articles",
    id: "E034",
    keywords: [
      "articles",
      "a",
      "an",
      "the",
      "zero article",
      "definite articles",
      "indefinite articles",
    ],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Present Perfect",
    id: "E035",
    keywords: [
      "present",
      "perfect",
      "have",
      "has",
      "participle",
      "past",
      "past participle",
      "tense",
      "since",
      "present perfect",
    ],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
  {
    topic: "Auxiliary Verbs",
    id: "E036",
    keywords: [
      "auxiliary",
      "auxiliary verbs",
      "modals",
      "can",
      "could",
      "may",
      "might",
      "must",
      "should",
      "does",
      "did",
      "do",
      "have",
      "has",
      "will",
    ],
    level: "Elementary",
    url: "/underConstruction",
    preUrl: "/grammar/elementary",
  },
];

const PreIntermediateGrammarList = [
  {
    topic: "Word Order in Questions",
    id: "P001",
    keywords: [
      "word",
      "order",
      "word order",
      "word order in questions",
      "questions",
      "subject questions",
    ],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Present Simple vs. Present Continuous",
    id: "P002",
    keywords: [
      "present",
      "simple present",
      "present simple",
      "present continuous",
      "continuous",
      "present simple vs. present continuous",
      "tense",
    ],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Past Simple vs. Past Continuous",
    id: "P003",
    keywords: [
      "past",
      "past simple",
      "simple Past",
      "past continuous",
      "continuous",
      "past simple vs. past continuous",
      "tense",
    ],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "When / After / Next / Then / Later",
    id: "P004",
    keywords: [
      "time sequencers",
      "connectors",
      "linking expressions",
      "Linkers",
      "when",
      "after",
      "next",
      "later",
      "then",
    ],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Because / So / But / Although",
    id: "P005",
    keywords: [
      "connectors",
      "linking expressions",
      "Linkers",
      "because",
      "so",
      "but",
      "although",
    ],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "be going to",
    id: "P006",
    keywords: [
      "going to",
      "be going to",
      "future",
      "future intentions",
      "plans",
      "future plans",
      "predictions",
      "future predictions",
      "tense",
    ],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Future Arrangements",
    id: "P007",
    keywords: ["present continuous", "future", "future arrangements", "tense"],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Relative Clauses",
    id: "P008",
    keywords: [
      "relative clauses",
      "relative pronouns",
      "who",
      "which",
      "where",
      "that",
    ],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Past Simple vs. Present Perfect",
    id: "P009",
    keywords: [
      "Past Simple",
      "Present Perfect",
      "tense",
      "have",
      "has",
      "since",
      "for",
    ],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Somebody / Something / Somewhere",
    id: "P010",
    keywords: [
      "somebody someone",
      "anybody anyone",
      "nobody no one",
      "something",
      "anything",
      "nothing",
      "somewhere",
      "anywhere",
      "nowhere",
    ],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Comparatives",
    id: "P011",
    keywords: ["comparatives", "comparison"],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Superlatives",
    id: "P012",
    keywords: ["superlatives", "comparison"],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Quantifiers",
    id: "P013",
    keywords: ["too much", "too many", "enough"],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "will / won't + v.",
    id: "P014",
    keywords: ["will not", "won't", "future prediction", "tense"],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "will / won't + v.",
    id: "P015",
    keywords: [
      "will not",
      "won't",
      "future prediction",
      "tense",
      "spontaneous decisions",
      "offers",
      "promises",
    ],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Infinitive (to + v.)",
    id: "P016",
    keywords: ["infinitive with to", "to + v."],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Gerund (v. + -ing)",
    id: "P017",
    keywords: ["gerund", "v. + -ing"],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Must / Have to / Should / Can't",
    id: "P018",
    keywords: ["must not", "mustn't", "have to", "has to", "should", "can't"],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Possessive Pronouns",
    id: "P019",
    keywords: [
      "possessive pronouns",
      "possession",
      "mine",
      "yours",
      "his",
      "hers",
      "its",
      "ours",
      "yours",
      "theirs",
    ],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "First Conditional",
    id: "P020",
    keywords: ["first conditional"],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Second Conditional",
    id: "P021",
    keywords: ["second conditional"],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Passive",
    id: "P022",
    keywords: ["passive"],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Use to",
    id: "P023",
    keywords: ["used to", "didn't use to"],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "May / Might",
    id: "P024",
    keywords: ["may", "might"],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Phrasal Verbs",
    id: "P025",
    keywords: ["phrasal verbs"],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "So / Neither",
    id: "P026",
    keywords: ["so", "neither", "nor", "too"],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Past Perfect",
    id: "P027",
    keywords: ["past perfect"],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
  {
    topic: "Reported Speech",
    id: "P028",
    keywords: ["reported speech", "indirect speech"],
    level: "Pre-Intermediate",
    url: "/underConstruction",
    preUrl: "grammar/preIntermediate",
  },
];

export { ElementaryGrammarList };
export { PreIntermediateGrammarList };
